import React, { FunctionComponent } from 'react';
import { MediaContainerCompProps } from '../MediaContainer.types';
import MediaContainerContent from './MediaContainerContent';
import styles from './styles/MediaContainer.scss';

const noop = () => {};

const MediaContainer: FunctionComponent<MediaContainerCompProps> = props => {
  const {
    id,
    children,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;

  return (
    <div
      id={id}
      className={styles.mediaContainer}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MediaContainerContent {...props}>{children}</MediaContainerContent>
    </div>
  );
};

export default MediaContainer;
